import React from "react"
import Layout from '../components/layout';
const AboutPage = () => (
  <Layout>
    <div className='bio'>
      <p>I'm Muriel nice to meet you! </p><br/>
      <p>📍 I live in Bozeman, Montana and I love developing apps, websites and learning new things 📱</p><br/>
      <p>I also love 😸cats, 🐶dogs, 🚲bikes, 🎮games and 🚙road trips<br/></p>
      <p>Check out some of my code on <a href="https://github.com/murielg" target="_blank">Github</a>, and connect with me on <a href="https://www.linkedin.com/in/murielgonzalez/" target="_blank">LinkedIn</a> ✌️</p>
    </div>
  </Layout>
);

export default AboutPage
